import VisibilityHandler from './../handlers/visibility'
import StepSelectedLabelHandler from './handlers/step-selected-labels'
import QuantityHandler from './../handlers/quantity'
import PricesHandler from './handlers/prices'
import DiscountsHandler from './handlers/discounts'
import DisabledConditionalOptions from './../handlers/disabled-conditional-options'
import PreviewImages from './../handlers/preview-images'
import ExampleImages from './../handlers/example-images'
import Serializer from './../serializerSystem'
import _ from 'lodash'

const Subf = class {
  constructor (configurator) {
    this.clone = false
    this.config_id = configurator.config_id
    this.id = configurator.id
    this.image = configurator.image || null
    this.name = configurator.name
    this.quantity = configurator.quantity || 1
    this.steps = configurator.steps || []
    this.exampleImages = configurator.example_images || {}
    this.imagesData = configurator['image-manager'].images || []

    if (!this.clone) {
      this.serializer = new Serializer(this)
    }

    this.price = configurator.price = {
      total: 0
    }
    this.discount = Number.parseFloat(configurator.discount) || 0
    this.previewImages = []

    this.handlers = {
      visibility: new VisibilityHandler(this),
      quantity: new QuantityHandler(this),
      stepSelectedLabel: new StepSelectedLabelHandler(this),
      prices: new PricesHandler(this),
      discounts: new DiscountsHandler(this),
      disabledConditionalOptions: new DisabledConditionalOptions(this),
      previewImages: new PreviewImages(this),
      exampleImages: new ExampleImages(this)
    }

    this.updatePriceStructure()
    this.update()
  }

  updatePriceStructure () {
    this.steps.forEach((step) => {
      const price = Number.parseFloat(step.price || 0)
      step.price = {
        total: price,
        self: price
      }
      if (step.options) {
        step.options.forEach((option) => {
          const price = Number.parseFloat(option.price || 0)

          option.price = {
            total: price,
            self: price
          }
        })
      }
    })
    return this
  };

  summary () {
    return {
      id: this.id,
      main_image: this.image,
      name: this.name,
      price: this.price,
      steps: this.getActiveSteps().filter(s => s.visible).map(step => {
        return {
          name: step.name,
          printDescription: step.printDescription,
          value: step.selectedLabel,
          price: step.price.total
        }
      })
    }
  }

  getActiveSteps () {
    return this.steps.filter(step => step.visible)
  }

  getSelectedOptions (stepId) {
    const step = this.steps.find(step => stepId === step.id)

    if (step) {
      return step.options.filter(option => option.selected)
    }

    return null
  }

  getOptions (stepId) {
    return this.steps.find(step => step.id === stepId).options || []
  }

  selectColor ({ id: stepId }, { id: colorId }) {
    const step = this.steps.find(step => step.id === stepId)
    const selectedColor = step.colors.find(c => c.selected)
    const newColor = step.colors.find(c => c.id === colorId)

    if (selectedColor.id !== newColor.id) {
      selectedColor.selected = false
      newColor.selected = true
    }

    this.update()

    return this
  }

  setQuantity ({ stepId, value }) {
    this.quantity = value
    this.steps.find(s => s.id === stepId).value = value

    this.update()
  }

  selectOption ({ id: stepId }, { id: optionId }) {
    const step = this.steps.find(step => step.id === stepId)
    const option = step.options.find(option => option.id === optionId)
    if (option && !option.selected) {
      if (step.type === 'simpleselect') {
        // unselect all other selected options
        step.options.forEach((stepOption) => {
          if (option.id !== stepOption.id && stepOption.selected) {
            stepOption.selected = false
            stepOption.price.total = 0
          }
        })
      } else if (step.type === 'conditional') {
        // unselect all other selected options
        step.options.forEach((stepOption) => {
          if (option.id !== stepOption.id && stepOption.type === 'select' && stepOption.selected) {
            stepOption.selected = false
            stepOption.price.total = 0
          }
        })
      }

      option.selected = true
    }

    this.update()

    return this
  }

  toggleOption ({ id: stepId }, { id: optionId }) {
    const step = this.steps.find(step => step.id === stepId)
    const option = step.options.find(option => option.id === optionId)

    option.selected = !option.selected

    this.update()

    return this
  }

  setDimension (step, type, value) {
    const currentStep = this.steps.find(s => s.id === step.id)
    if (typeof currentStep !== 'undefined') {
      currentStep[type].value = Number.parseInt(value)
    }

    this.update()

    return this
  }

  filteredImages () {
    const colorId = this.steps.find(step => step.type === 'color').colors.find(c => c.selected).id
    const images = this.imagesData
    return images.filter(image => {
      if (image.color_id === colorId || !image.color_id) {
        return image
      }
    })
  }

  getPreviewImages () {
    const previewImages = this.filteredImages()
    let filteredImages = previewImages.filter(image => {
      const option = this.getOption({
        stepId: image.step_id,
        optionId: image.option_id
      })
      let result = !!option && option.selected
      if (image.extra_option_ids) {
        image.extra_option_ids.forEach(opt => {
          const optionId = (opt && opt.option) ? opt.option : opt
          const o = this.getOption({ optionId })
          if (o) {
            result &= o.selected
          }
        })
      }
      return result
    })
    if (filteredImages.length === 0) {
      if (!this.image) {
        filteredImages = []
      } else {
        filteredImages = [{
          path: this.image
        }]
      }
    }
    filteredImages.sort(function (a, b) {
      return (a.order || 0) >= (b.order || 0)
    })
    return filteredImages
  }

  getExampleImages () {
    return this.handlers.exampleImages.process()
  }

  getOption ({ stepId, optionId }) {
    if (stepId) {
      const step = this.steps.find(step => step.id === stepId)

      return step.options.find(option => option.id === optionId)
    } else {
      let option

      this.steps.find(step => {
        option = (step.options || []).find(o => o.id === optionId)

        return !!option
      })

      return option
    }
  }

  update () {
    this.handlers.visibility.process()
    this.handlers.quantity.process()
    this.handlers.disabledConditionalOptions.process()
    this.handlers.prices.process()
    this.handlers.discounts.process()

    if (!this.clone) {
      this.handlers.stepSelectedLabel.process()
    }

    return this
  }

  selectDimensionTable ({ stepId, height, width }) {
    const step = this.steps.find(s => s.id === stepId)

    // unselect old selected
    step.options.find(o => o.selected).selected = false

    // find and select new option
    step.options.find(o => o.height === height && o.width === width)
      .selected = true

    this.update()
  }

  getDimensionStep () {
    return this.steps.find(step => step.type === 'dimension')
  }

  calculateTotalPrice ({ step, option }) {
    return new Promise((resolve, reject) => {
      console.time('calc price all')
      const clone = _.cloneDeep(this)
      clone.clone = true
      // clone.selectOption(step, option);
      // clone.selectOption({id: step}, {id: option});
      // this.getOption({stepId: step.id, optionId: option.id,}).price.all = clone.price;
      this.getOption({ stepId: step, optionId: option }).price.all = clone.price
      resolve(clone.price)
      console.timeEnd('calc price all')
    })
  }
}

export default Subf
