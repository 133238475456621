import * as types from '../mutation-types'

// state
export const state = {
  categories: null,
  category: null,
  product: null,
  products: null
}

// getters
export const getters = {
  categories: state => state.categories,
  category: state => state.category,
  product: state => state.product,
  products: state => state.products
}

// mutations
export const mutations = {
  [types.UPDATE_PRODUCT] (state, id) {
    state.product = id
  },
  [types.SET_CATEGORIES] (state, categories) {
    state.categories = categories
  },
  [types.SET_CATEGORY] (state, category) {
    state.category = category
  },
  [types.SET_PRODUCTS] (state, products) {
    state.products = products
  }
}

// actions
export const actions = {
  setProducts ({ commit }, { products }) {
    commit(types.SET_PRODUCTS, products)
  },
  setCategories ({ commit }, { categories }) {
    commit(types.SET_CATEGORIES, categories)
  },
  setCategory ({ commit }, { category }) {
    commit(types.SET_CATEGORY, category)
  },
  updateProductId ({ commit }, { id }) {
    commit(types.UPDATE_PRODUCT, id)
  }
}
