import _ from 'lodash'
export default class {
  constructor (configurator) {
    this.configurator = configurator
  }

  process () {
    this.updateVisibility()
    this.updateSelection()
    this.handleDimensionTable()
    this.handleDimensionGarage()
    if (this.configurator.steps.find((s) => s.type === 'dimension')) {
      this.handleDimension()
    }
    return this
  }

  handleDimensionTable () {
    this.configurator.steps.filter(s => s.type === 'dimension_table').forEach(s => {
      s.options = s.options.filter(o => o.price.self > 0)
      // select first if none selected
      if (!s.options.find(o => o.selected) && s.options.length > 0) {
        const minWidth = _.uniq(s.options.map(o => o.width)).sort()[0]
        const minHeight = _.uniq(s.options.filter(o => o.width === minWidth).map(o => o.height)).sort()[0]
        const optionWithMinDim = s.options.find(o => o.width === minWidth && o.height === minHeight)
        optionWithMinDim.selected = true
      }
    })
  }

  handleDimensionGarage () {
    this.configurator.steps.filter(s => s.type === 'dimension_garage').forEach(s => {
      s.options = s.options.filter(o => o.price.self > 0)
      // select first if none selected
      if (!s.options.find(o => o.selected) && s.options.length > 0) {
        const minWidth = _.uniq(s.options.map(o => o.width)).sort()[0]
        const minHeight = _.uniq(s.options.filter(o => o.width === minWidth).map(o => o.height)).sort()[0]
        const optionWithMinDim = s.options.find(o => o.width === minWidth && o.height === minHeight)
        optionWithMinDim.selected = true
      }
    })
  }

  handleDimension () {
    const step = this.configurator.steps.find(s => s.model_step)
    /*
    const option = step.options.find(o => o.selected);
    let dim_step = this.configurator.steps.find(s => s.type === 'dimension');
    dim_step.height = option.dimension.height;
    dim_step.width = option.dimension.width;
    */

    if (step && step.options) {
      const option = step.options.find(o => o.selected)
      if (option && typeof option.standart_dim !== 'undefined') {
        // unselect all option dimension
        option.standart_dim.find(o => {
          if (o && o.selected) { o.selected = false }
        })
        option.special_dim.find(s => {
          if (s && s.selected) { s.selected = false }
        })
        // get dimension step values
        const currentDim = this.configurator.getActiveSteps().find(s => s.type === 'dimension')
        const w = Number.parseInt(currentDim.width.value)
        const h = Number.parseInt(currentDim.height.value)

        // find standart dimension values
        const optionWithMinDim = option.standart_dim.find(o => Number.parseInt(o.width) === w && Number.parseInt(o.height) === h)
        // check
        if (optionWithMinDim) {
          // if standart price = 0
          if (Number.parseInt(optionWithMinDim.price) === 0) {
            this.selectSpecial(option, w, h)
            // unselect standart values
            optionWithMinDim.selected = false
          } else {
            // select standart values
            optionWithMinDim.selected = true
            // unselect special
            option.special_dim.filter(o => {
              if (o.selected) {
                o.selected = false
              }
            })
          }
        } else {
          option.special_dim.filter(s => {
            const hFrom = Number.parseInt(s.height.from)
            const hTo = Number.parseInt(s.height.to)
            const wFrom = Number.parseInt(s.width.from)
            const wTo = Number.parseInt(s.width.to)
            if (hTo <= 0) {
              if ((hFrom <= h && hTo === 0) && (wFrom <= w && wTo >= w)) {
                s.selected = true
              }
            } else {
              if ((hFrom <= h && hTo >= h) && (wFrom <= w && wTo >= w)) {
                if (s.price === 0) {
                  this.selectStandart(option)
                } else {
                  s.selected = true
                }
              }
            }
          })
        }
      }
    }
  }

  selectStandart (option) {
    const currentDim = this.configurator.getActiveSteps().find(s => s.type === 'dimension')
    // const w = Number.parseInt(currentDim.width.value)
    // const h = Number.parseInt(currentDim.height.value)
    // find standart dimension values
    // selected option default dimension
    const dims = this.configurator.getModelSelectOption()
    const optionWithMinDim = option.standart_dim.find(o => o.width === dims.width.min && o.height === dims.height.min)
    if (optionWithMinDim) {
      optionWithMinDim.selected = true
      currentDim.width.value = dims.width.min
      currentDim.height.value = dims.height.min
    }
  }

  selectSpecial (option, w, h) {
    option.special_dim.filter(s => {
      const hFrom = Number.parseInt(s.height.from)
      const hTo = Number.parseInt(s.height.to)
      const wFrom = Number.parseInt(s.width.from)
      const wTo = Number.parseInt(s.width.to)
      // find special values and set selected
      if ((hFrom <= h && hTo >= h) && (wFrom <= w && wTo >= w)) {
        s.selected = true
      }
    })
  }

  updateVisibility () {
    this.configurator.steps.forEach((step) => {
      this.processRestrictions(step)

      if (step.options) {
        step.options.forEach((option) => {
          this.processRestrictions(option)
        })
      }
      if (step.colors) {
        step.colors.forEach((color) => {
          this.processRestrictions(color)
        })
      }
    })
  }

  prepareRestrictions (obj) {
    const gen = function * entries (obj) {
      for (const key of Object.keys(obj)) {
        yield [key, obj[key]]
      }
    }

    const restrictions = {
      only: [],
      not: []
    }

    if (obj.only_for && obj.only_for.selection) {
      for (const [stepId, options] of gen(obj.only_for.selection)) {
        options.forEach((optionId) => {
          restrictions.only.push({ stepId, optionId })
        })
      }
    }

    if (obj.not_for && obj.not_for.selection) {
      for (const [stepId, options] of gen(obj.not_for.selection)) {
        options.forEach((optionId) => {
          restrictions.not.push({ stepId, optionId })
        })
      }
    }

    return restrictions
  }

  processRestrictions (object) {
    let visible = true
    const restrictions = this.prepareRestrictions(object)

    if (restrictions.only && restrictions.only.length > 0) {
      visible = !!restrictions.only.find(({ stepId, optionId }) => {
        const option = this.configurator.getOption({ stepId, optionId })

        return option && option.selected
      })
    }

    if (visible && restrictions.not && restrictions.not.length > 0) {
      visible = !restrictions.not.find(({ stepId, optionId }) => {
        const option = this.configurator.getOption({ stepId, optionId })
        return option && option.selected
      })
    }
    object.visible = visible
  }

  updateSelection () {
    this.configurator.steps.forEach((step) => {
      if (step.options) {
        step.options.forEach((option) => {
          if (!option.visible) {
            option.selected = false
          }
        })
      }
    })
  }
}
