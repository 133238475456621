export default class {
  constructor (configurator) {
    this.configurator = configurator
  }

  process () {
    this.configurator.steps.filter(s => s.type === 'conditional' ||
    s.type === 'montage_step' ||
    s.type === 'glass' ||
    s.type === 'color_roof' ||
    s.type === 'color_screen').forEach(step => {
      step.options.forEach(option => {
        if ((option.show_for || []).length > 0) {
          if (!step.options.find(o => option.show_for.indexOf(o.id) > -1 && o.selected)) {
            option.disabled = true
            option.dname = 'children'

            return
          }
        } else {
          option.children = []
        }
        option.disabled = false
      })
    })
    // this.checkWidthConditional()
    return this
  }

  /**
   * Compare current Menge width values with conditional option if isset dimension range
   * Sample: Menge > 4m then show Elektrisch option (Flugeltore)
   */
  checkWidthConditional () {
    const widthConditional = []
    this.configurator.steps.filter(s => s.type === 'conditional').forEach(step => {
      step.options.forEach(option => {
        if (option && option.widthConditional) {
          widthConditional.push({ stepId: step.id, optionId: option.id })
        }
      })
    })
    // checking values
    if (widthConditional.length > 0) {
      const dimension = this.configurator.steps.find(s => s.type === 'dimension')
      widthConditional.forEach(w => {
        const step = this.configurator.steps.find(s => s.id === w.stepId)
        if (typeof step !== 'undefined') {
          const option = step.options.find(o => o.id === w.optionId)
          if (typeof option !== 'undefined') {
            const wDimension = option.widthConditional
            if (wDimension.from > 0 && wDimension.to > 0) {
              if (dimension.width.value >= wDimension.from && dimension.width.value <= wDimension.to) {
                step.options.filter(option => {
                  option.selected = false
                })
                option.visible = true
                option.disabled = false
                option.selected = true
              } else {
                step.options[0].selected = true
                option.visible = false
                option.disabled = true
              }
            }
          }
        }
      })
    }
  }
}
