export default class {
  constructor (configurator) {
    this.configurator = configurator
  }

  process () {
    this.updateSelectedLabels()

    return this
  }

  updateSelectedLabels () {
    this.configurator.steps.forEach((step) => {
      if (step.type === 'quantity') {
        step.selectedLabel = step.value + ' Stück'
        return
      }

      if (step.type === 'dimension_table' || step.type === 'dimension_garage') {
        const option = step.options.find(o => o.selected)
        step.selectedLabel = option.width + step.unit + ' x ' + option.height + step.unit
        return
      }

      if (step.options) {
        const selectedOptionNames = step.options
          .filter((option) => option.selected && !option.disabled)
          .map(o => {
            // o.type === 'count' ? o.value + 'x ' + o.name  : o.name
            if (o.type === 'count') {
              return o.value + 'x ' + o.name
            } else {
              if (o && o.sub_options) {
                const selected = o.sub_options.filter(s => s.selected).map(s => s.name)
                if (selected.length > 0) {
                  o.selectedLabel = o.name + ' (' + selected.join(', ') + ')'
                  return o.selectedLabel
                }
              } else {
                return o.name
              }
            }
          })

        if (selectedOptionNames.length > 0) {
          step.selectedLabel = selectedOptionNames.join(', ')

          return
        }
      }

      // only for color step
      if (step.type === 'color') {
        const color = step.colors.find((color) => color.selected)
        step.selectedLabel = color.label || color.name

        return
      }

      if (step.type === 'dimension' && step.width && step.height) {
        const options = this.configurator.steps.find(s => s.model_step).options
        const option = options.find(o => o.selected)
        // change old step height
        step.height.value = option.dimension.height.value
        step.height.max = option.dimension.height.max
        step.height.min = option.dimension.height.min
        step.height.unit = option.dimension.height.unit
        step.height.step = option.dimension.height.step
        // change old step width
        step.width.value = option.dimension.width.value
        step.width.max = option.dimension.width.max
        step.width.min = option.dimension.width.min
        step.width.unit = option.dimension.width.unit
        step.width.step = option.dimension.width.step
        /*
        // change old step height
        step.height.value = option.dimension.height.value
        step.height.max = option.dimension.height.max
        step.height.min = option.dimension.height.min
        step.height.unit = option.dimension.height.unit
        step.height.step = option.dimension.height.step
        // change old step width
        step.width.value = option.dimension.width.value
        step.width.max = option.dimension.width.max
        step.width.min = option.dimension.width.min
        step.width.unit = option.dimension.width.unit
        step.width.step = option.dimension.width.step
        // change old step length
        step.length.value = option.dimension.length.value
        step.length.max = option.dimension.length.max
        step.length.min = option.dimension.length.min
        step.length.unit = option.dimension.length.unit
        step.length.step = option.dimension.length.step
        // change old step height
        const h2 = typeof option.dimension.height_additional !== 'undefined' ? ' x ' + option.dimension.height_additional.value + step.height_additional.unit : ''
        if (typeof option.dimension.height_additional !== 'undefined') {
          step.height_additional.value = option.dimension.height_additional.value
          step.height_additional.max = option.dimension.height_additional.max
          step.height_additional.min = option.dimension.height_additional.min
          step.height_additional.unit = option.dimension.height_additional.unit
          step.height_additional.step = option.dimension.height_additional.step
        }

        if (option && option.dimension) {
          step.selectedLabel = option.dimension.width.value + option.dimension.width.unit + ' x ' + option.dimension.length.value + option.dimension.length.unit + ' x ' + option.dimension.height.value + option.dimension.height.unit + h2
        } else {
          step.selectedLabel = step.width.value + step.width.unit + ' x ' + step.length.value + step.length.unit + ' x ' + step.height.value + step.height.unit + h2
        }
        */
        if (option && option.dimension) {
          step.selectedLabel = option.dimension.width.value + option.dimension.width.unit + ' x ' + option.dimension.height.value + option.dimension.height.unit
        } else {
          step.selectedLabel = step.width.value + step.width.unit + ' x ' + step.height.value + step.height.unit
        }

        return
      }
      if (step.type === 'conditional') {
        step.options.map(o => {
          if (o.group === 'montage') {
            step.selectedLabel = this.getDimensionMenge()
            o.name = this.getDimensionMenge()
          }
        })
      }

      step.selectedLabel = '--'
    })
  }

  getDimensionMenge () {
    const modelStep = this.configurator.steps.find(s => s.model_step)
    const option = modelStep.options.find(o => o.selected)
    const qty = this.configurator.steps.find(s => s.type === 'quantity').value

    const total = (option.dimension.width.value * qty) / 100
    const label = 'Montagekosten für die Zaunanlage:€' + Math.round(total * 50) + '.00'
    return label
  }
}
