/* eslint-disable no-cond-assign */
/* eslint-disable no-case-declarations */
import Slugify from 'slugify'
export default class {
  constructor (configurator) {
    this.configurator = configurator
  }

  encode () {
    return btoa(JSON.stringify(this.configurator.summary()))
  }

  slugify (string) {
    return Slugify(string, { lower: true })
  }

  serialize () {
    const convert = this.slugify
    const definition = []

    const activeSteps = this.configurator.getActiveSteps()
    activeSteps.forEach(step => {
      const stepDefinition = []
      switch (step.type) {
        case 'dimension':
          const options = this.configurator.steps.find(s => s.model_step).options
          const option = options.find(o => o.selected)
          if (option && option.dimension) {
            stepDefinition.push(option.dimension.width.value + 'x' + option.dimension.height.value)
          }
          break
        case 'color':
          stepDefinition.push(convert(step.colors.find(c => c.selected).name))
          break
        case 'quantity':
          stepDefinition.push(step.value)
          break
        case 'simpleselect':
        case 'montage_step':
        case 'conditional':
          step.options.forEach(option => {
            if (option.selected) {
              stepDefinition.push(convert(option.name))
            } else if (option.type === 'count' && !option.disabled) {
              stepDefinition.push(convert(option.name) + '[' + option.value + ']')
            }
          })
          break
        case 'dimension_table':
          step.options.find(option => {
            if (option && option.selected) {
              stepDefinition.push(option.width + 'x' + option.height)
            }
          })
          break
      }
      definition.push(convert(step.name) + ':' + stepDefinition.join(','))
    })
    return definition.join(';')
  }

  selectOption (step, optionValue) {
    console.log(step, optionValue)
  }

  getStep ({ name }) {
    return this.configurator.steps.find(s => Slugify(s.name, { lower: true }) === name)
  }

  getSystemStep ({ name }) {
    return this.configurator.base.steps.find(s => Slugify(s.name, { lower: true }) === name)
  }

  deserialize (definition) {
    const parameters = this.parseDefinition(definition)

    if (parameters.length > 0) {
      parameters.forEach(({ step: stepName, value: values }) => {
        const step = this.getStep({ name: stepName })
        if (step) {
          values.forEach(value => {
            switch (step.type) {
              case 'color':
                let color
                if (color = step.colors.find(c => Slugify(c.name, { lower: true }) === value)) {
                  this.configurator.selectColor(step, color)
                }
                break
              case 'simpleselect':
              case 'conditional':
                let option
                if (option = step.options.find(c => Slugify(c.name, { lower: true }) === value)) {
                  this.configurator.selectOption(step, option)
                }
                break
              case 'dimension':
                const dim = value.split('x')
                this.configurator.setDimension(step, 'width', dim[0])
                this.configurator.setDimension(step, 'height', dim[1])
                break
              case 'dimension_table':
                const dimTable = value.split('x')
                this.configurator.selectDimensionTable({ stepId: step.id, height: dimTable[1], width: dimTable[0] })
                break
            }
          })
        }
      })
    }
  }

  parseDefinition (definition) {
    const data = []

    if (!definition) {
      return data
    }

    definition.split(';').forEach(part => {
      const parts = part.split(':')

      data.push({
        step: parts[0].toLowerCase(),
        value: parts[1].split(',').map(val => {
          return val.toLowerCase()
        })
      })
    })

    return data
  }
}
