export default class {
  constructor (configurator) {
    this.configurator = configurator
    this.totalDiscount = 0
  }

  process () {
    this.totalDiscount = 0
    this.updateDiscounts()

    return this
  }

  updateDiscounts () {
    this.configurator.steps.forEach((step) => {
      // if (step.options && !step.price_all) {
      if (step.options) {
        step.options.forEach((option) => {
          this.updateOption(option)
          if (typeof option.price_factor !== 'undefined') {
            if (option.discount > 0 && option.selected) {
              const dimension = this.configurator.steps.find(s => s.type === 'dimension')
              if (typeof dimension !== 'undefined') {
                const height = dimension.height.value / 100
                const width = dimension.width.value / 100

                const total = (height * width * option.price_factor)
                const discount = Number.parseFloat(option.discount) / 100 * total
                // console.log(option);
                option.price.total = total
                option.price.discounted = total - discount
                this.totalDiscount += discount
              }
            }
          }
        })
      }
    })

    // console.log('total discount: ' + this.totalDiscount); // sum discount total
    this.configurator.price.old_price = Math.ceil(this.configurator.price.total)
    this.configurator.price.total -= this.totalDiscount
    const configDiscount = this.configurator.discount
    const configTotalPrice = this.configurator.price.total
    this.configurator.price.discounted = Math.ceil(configTotalPrice - (configTotalPrice / 100 * configDiscount))
  }

  updateOption (option) {
    if (option.price && option.discount) {
      const discount = Number.parseFloat(option.discount) / 100 * option.price.total
      option.price.discounted = option.price.total - discount
      // console.log(option.price)
      this.totalDiscount += discount
    }
  }

  /* updateDimensionTable(option) {
    if(option.discount > 0) {

      let discount = 0,
          total = 0;
      if(typeof option.price.all !== 'undefined') {
        discount = Number.parseFloat(option.discount) / 100 * option.price.all.total;
        total = option.price.all.total;
        option.price.all.discounted = total - discount;
      }
      option.price.discounted = total - discount;
      option.price.total = total;
      this.totalDiscount += discount;
      console.log(discount);
    }
  } */
}
