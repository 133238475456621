<template>
<div class="wrapper">
  <Header />
  <child />
  <Footer />
</div>
</template>
<script>
import Header from './partials/header'
import Footer from './partials/footer'
export default {
  name: 'DefaultLayout',
  components: {
    Header,
    Footer
  }
}
</script>
