import * as types from '../mutation-types'
import Slugify from 'underscore.string/slugify'
import axios from 'axios'
// state
export const state = {
  relateds: []
}
// getters
export const getters = {
  relateds: state => state.relateds
}
// mutations
export const mutations = {
  [types.SET_RELATED] (state, related) {
    state.relateds = related
  }
}
// actions
export const actions = {
  async setRelated ({ commit }, data) {
    const apiKey = btoa(process.env.VUE_APP_API_KEY)
    const apiUrl = process.env.VUE_APP_API_URL
    await axios.post(apiUrl + 'configurator/related', { re: apiKey, item: data[0].id }).then(resp => {
      const configs = resp.data
      if (configs) {
        const related = []
        const model = data[0].steps[0].value
        configs.forEach(c => {
          const option = c.options.find(o => o.name === model)
          if (typeof option !== 'undefined') {
            related.push({
              slug: c.slug + '/muster:' + Slugify(option.name),
              name: c.name,
              image: option.image,
              option: option.name
            })
          }
        })
        commit(types.SET_RELATED, related)
      }
    })
  }
}
