import * as types from '../mutation-types-system'
import * as Routes from '~/lib/routes'
import Serializer from '~/lib/serializerSystem'
// import axios from 'axios'
import SystemClass from '~/lib/system'
// state
export const state = {
  system: null,
  loading: true,
  cLoading: false,
  name: null,
  dimension: null,
  availableWidth: null,
  totalPrices: [],
  discount: 0,
  price: {
    discounted: 0,
    total: 0
  },
  previewImagesLoading: true,
  previewImages: [],
  exampleImages: [],
  exampleImagesLoading: true,
  animations: [],
  products: [],
  steps: [
    {
      id: null,
      visible: true,
      options: []
    }
  ],
  menu: [
    {
      id: null,
      name: null,
      sort: 0
    }
  ],
  currentStep: null,
  mailboxName: null
}
// getters
export const getters = {
  system: state => state.system,
  name: state => state.name,
  steps: state => state.steps,
  menu: state => state.menu,
  discount: state => state.discount,
  price: state => state.price,
  previewImagesLoading: state => state.previewImagesLoading,
  previewImages: state => state.previewImages,
  exampleImages: state => state.exampleImages,
  getMainImage: state => state.main_image,
  mailboxName: state => state.mailboxName,
  animations: state => state.animations,
  products: state => state.products,
  dimension: state => state.dimension,
  availableWidth: state => state.availableWidth,
  loading: state => state.loading,
  cLoading: state => state.cLoading,
  hasNextStep: (state) => {
    if (!state.currentStep && state.steps.length > 1) {
      return true
    }
    let found = false
    let hasNextStep = false
    state.steps.find(s => {
      if (found) {
        hasNextStep = true
        return true
      }
      found = s.id === state.currentStep
      return false
    })
    return hasNextStep
  },
  currentOptions: (state, getters) => {
    const currentStep = state.steps.find(step => step.id === state.currentStep) || state.steps.find(step => step.visible)
    return (currentStep.options || []).filter(step => step.visible)
  },
  currentStep: (state) => {
    return state.steps.find(step => step.id === state.currentStep) ||
      state.steps.find(step => step.visible)
  },
  totalPrice: (state) => ({ id }) => {
    return state.totalPrices.find(o => o.id === id)
  },
  getOptionsSelected: (state) => {
    if (state.system !== null) {
      return state.system.options[0].selected
    }
  },
  getFromMainConfig: (state) => {
    if (state.system !== null) {
      return state.system.getFromMainConfig()
    }
  },
  getFromSub1Config: (state) => {
    if (state.system !== null) {
      return state.system.getFromSub1Config()
    }
  },
  getFromSub2Config: (state) => {
    if (state.system !== null) {
      return state.system.getFromSub2Config()
    }
  },
  getBaseSubSteps: (state) => {
    if (state.system !== null) {
      return state.system.getBaseSubSteps()
    }
  },
  getFromSub3Config: (state, getters) => {
    if (state.system !== null) {
      return state.system.getFromSub3Config()
    }
  },
  getSelection: (state) => {
    if (state.system !== null) {
      const step = state.system.main.steps.find(step => step.type === 'dimension')
      const values = []
      for (let i = step.width.min; i <= step.width.max; i += step.width.step) {
        values.push({
          value: i,
          label: `${i} ${step.width.unit}`,
          selected: step.width.value === i
        })
      }
      return values
    }
  },
  getBaseStepsOptions: (state) => {
    if (state.system !== null) {
      const stepId = state.system.config_main.steps[0]
      const step = state.system.main.steps.find(step => step.id === stepId)
      const array = {
        parent: [],
        child: []
      }
      step.options.forEach(option => {
        if (typeof option.show_for !== 'undefined') {
          array.child.push(option)
        } else {
          array.parent.push(option)
        }
      })
      return array
    }
  },
  getSelectionSubTwo: (state) => {
    if (state.system !== null) {
      const step = state.system.sub_2.steps.find(step => step.type === 'dimension')
      const values = []
      for (let i = step.width.min; i <= step.width.max; i += step.width.step) {
        values.push({
          value: i,
          label: `${i} ${step.width.unit}`,
          selected: step.width.value === i
        })
      }
      return values
    }
  },
  getSelectionSubOne: (state) => {
    if (state.system !== null) {
      const step = state.system.sub_1.steps.find(step => step.type === 'dimension')
      const values = []
      for (let i = step.width.min; i <= step.width.max; i += step.width.step) {
        values.push({
          value: i,
          label: `${i} ${step.width.unit}`,
          selected: step.width.value === i
        })
      }
      return values
    }
  },
  getTotalPrice: state => {
    if (state.system !== null) {
      return state.system.getTotalPrices()
    }
  },
  getOptionsPfosten: (state) => {
    if (state.system !== null) {
      return state.system.getOptionsConfig()
    }
  },
  getMetersFromConfigs: state => {
    if (state.system !== null) {
      return state.system.getMetersFromConfigs()
    }
  },
  summary: state => {
    const summary = state.system ? state.system.summary() : {}
    return {
      ...summary
    }
  }
}
// mutations
export const mutations = {
  [types.SET_SYSTEM] (state, system) {
    state.system = system
  },
  [types.SET_NAME] (state, name) {
    state.name = name
  },
  [types.SET_DIMENSION] (state, dimension) {
    state.dimension = dimension
  },
  [types.SET_AVAILABLE_WIDTH] (state, availableWidth) {
    state.availableWidth = availableWidth
  },
  [types.SET_PREVIEW_IMAGES] (state, images) {
    state.previewImages = images
  },
  [types.SET_PREVIEW_IMAGES_LOADING] (state, status) {
    state.previewImagesLoading = status
  },
  [types.SET_EXAMPLE_IMAGES] (state, images) {
    state.exampleImages = images
  },
  [types.SET_EXAMPLE_IMAGES_LOADING] (state, status) {
    state.exampleImagesLoading = status
  },
  [types.SET_ANIMATIONS] (state, animations) {
    state.animations = animations
  },
  [types.SET_LOADING] (state, status) {
    state.loading = status
  },
  [types.SET_SYSTEM_LOADING] (state, status) {
    state.cLoading = status
  },
  [types.SET_STEPS] (state, steps) {
    state.steps = steps
  },
  [types.SET_CURRENT_STEP] (state, id) {
    state.currentStep = id
  },
  [types.SET_PRICE] (state, price) {
    state.price = price
  },
  [types.SET_LOADING_PRICE_ALL] (state, { status, option }) {
    state.totalPrices.find(o => o.id === option.id).loading = status
  },
  [types.SET_PRICE_ALL] (state, { price, option }) {
    Object.assign(state.totalPrices.find(o => o.id === option.id), price)
  },
  [types.SET_MENU] (state, menu) {
    state.menu = menu
  },
  [types.SET_PRODUCTS] (state, products) {
    state.products = products
  },
  [types.SET_NEXT_STEP] (state) {
    let step = null
    let found = false
    state.steps.find(s => {
      if (found) {
        step = s
        return true
      }
      found = s.id === state.currentStep || !state.currentStep
      return false
    })
    state.currentStep = step.id
  },
  [types.SET_MAILBOX_NAME] (state, mailbox) {
    state.mailboxName = mailbox
  }
}
// actions
export const actions = {
  setSystem ({ commit }, config) {
    const system = new SystemClass(config)
    console.log(system)
    // eslint-disable-next-line no-unused-vars
    let definition
    // eslint-disable-next-line no-cond-assign
    if (definition = Routes.definitionSystem()) {
      const serialize = new Serializer(system)
      serialize.deserialize(definition)
    }
    // init config
    system.initializeSystem()
    commit(types.SET_SYSTEM, system)
    commit(types.SET_ANIMATIONS, config.base['image-manager'].animations || [])
    commit(types.SET_MENU, system.getStepMenu())
    commit(types.SET_STEPS, system.getSteps())
    commit(types.SET_NAME, system.base.name)
    commit(types.SET_DIMENSION, system.dimension)

    const products = state.system.products
    commit(types.SET_PRODUCTS, products)
    commit(types.SET_AVAILABLE_WIDTH, state.system.availableWidth)
    commit(types.SET_SYSTEM_LOADING, true)
    // commit(types.checkout/SET_MONTAGE_COST, system.montage, { root: true }) // set global mutation
    // commit(typescheckout/SET_MONTAGE_STATUS, false, { root: true }) // set global mutation
    // build price all stuff
    system.base.steps.filter(s => s.price_all).forEach(s => {
      s.options.forEach(o => {
        state.totalPrices.push({
          id: o.id,
          discounted: 0,
          total: 0,
          loading: false
        })
      })
    })
    refreshLiveImages({ commit, state })
  },
  refreshImages (context) {
    refreshExampleImages(context)
  },
  setCurrentStep ({ commit, state }, id) {
    commit(types.SET_CURRENT_STEP, id)
    commit(types.SET_PRICE, state.system.price)
  },
  selectMainOption ({ commit, state }, option) {
    const system = state.system
    system.selectOptionAll(option)
    commit(types.SET_MENU, system.getStepMenu())
    commit(types.SET_STEPS, system.getSteps())
    commit(types.SET_PREVIEW_IMAGES_LOADING, true)
    refreshLiveImages({ commit, state })
  },
  toggleOption ({ commit, state, getters }, option) {
    const system = state.system
    system.toggleOption(getters.currentStep, option)
    commit(types.SET_STEPS, system.getActiveSteps())
    commit(types.SET_PRICE, system.price)
    commit(types.SET_PREVIEW_IMAGES_LOADING, true)
    refreshLiveImages({ commit, state })
  },
  selectColor ({ commit, state }, color) {
    state.system.selectColorAll(color)
    commit(types.SET_MENU, state.system.getStepMenu())
    commit(types.SET_STEPS, state.system.getSteps())
    commit(types.SET_PREVIEW_IMAGES_LOADING, true)
    refreshLiveImages({ commit, state })
  },
  setDimensionBase ({ commit, state }, { type, value }) {
    state.system.setDimensionBase(type, value)
    commit(types.SET_DIMENSION, state.dimension)
    commit(types.SET_AVAILABLE_WIDTH, state.system.availableWidth)
  },
  setDimension ({ commit, state }, { config, type, value }) {
    state.system.setDimension(config, type, value)
    commit(types.SET_AVAILABLE_WIDTH, state.system.availableWidth)
  },
  changeConfigShow ({ commit, state }, config) {
    state.system.setConfigShow(config)
    state.system.calculateAmountProducts()
    state.system.setMontageCost()
    const products = state.system.products
    commit(types.SET_PRODUCTS, products)
    commit(types.SET_AVAILABLE_WIDTH, state.system.availableWidth)
    commit(types.SET_STEPS, state.system.getSteps())
    commit(types.SET_SYSTEM_LOADING, true)
    // commit(types.checkout/SET_MONTAGE_COST, state.system.montage, { root: true }) // set global mutation

    if (config.config === 'sub_3' && Number.parseInt(config.checked) === 0) {
      commit(types.SET_MAILBOX_NAME, null)
    }
  },
  changeConfigOptionsShow ({ state }, value) {
    state.system.setQuantity(value)
  },
  setSubTwoQuantity ({ state }, val) {
    state.system.setSubTwoQuantity(val)
  },
  setMainOption ({ state }, value) {
    state.system.setMainOptions(value)
  },
  setSubOneOption ({ state }, value) {
    state.system.setSubOneOption(value)
  },
  setSubTwoOptions ({ commit, state }, value) {
    state.system.setSubTwoOption(value)
    refreshLiveImages({ commit, state })
  },
  setSubThreeOptions ({ commit, state }, value) {
    state.system.setSubThreeOption(value)
    commit(types.SET_MAILBOX_NAME, state.system.getMailboxName())
    refreshLiveImages({ commit, state })
  },
  setOptionsValue ({ commit, state }, value) {
    state.system.setOptionsValue(value)
    commit(types.SET_MAILBOX_NAME, state.system.getMailboxName())
    commit(types.SET_STEPS, state.system.getSteps())
    refreshLiveImages({ commit, state })
  },
  setMontage ({ commit, state }, value) {
    state.system.setMontageToBase(value)
    // commit('checkout/SET_MONTAGE_STATUS', value, { root: true })
  }
}
function refreshLiveImages ({ commit, state }) {
  const images = state.system.getPreviewImages()
  commit(types.SET_PREVIEW_IMAGES, images)
  commit(types.SET_PREVIEW_IMAGES_LOADING, false)
  refreshExampleImages({ commit, state })
}
function refreshExampleImages ({ commit, state }) {
  commit(types.SET_EXAMPLE_IMAGES_LOADING, true)
  const exampleImages = state.system.main.getExampleImages()
  exampleImages.forEach(images => {
    commit(types.SET_EXAMPLE_IMAGES, images)
    commit(types.SET_EXAMPLE_IMAGES_LOADING, false)
  })
}
