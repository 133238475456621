import * as types from '../mutation-types'
// state
export const state = {
  from: 0,
  to: 5
}
// getters
export const getters = {
  from: state => state.from,
  to: state => state.to
}
// mutations
export const mutations = {
  [types.UPDATE_SLIDER_FROM] (state, from) {
    state.from = from
  },
  [types.UPDATE_SLIDER_TO] (state, to) {
    state.to = to
  }
}
// actions
export const actions = {
  setSliderRange ({ commit }, data) {
    commit(types['UPDATE_SLIDER_' + data.type], data.value)
  }
}
