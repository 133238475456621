<template>
  <div class="text-center">
    <div class="spinner-border" :class="color" :style="'width:' + size + 'rem; height:' + size + 'rem;'" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Spiner',
  props: {
    size: {
      default: 5
    },
    color: {
      default: 'text-dark'
    }
  }
}
</script>
