export default class {
  constructor (configurator) {
    this.configurator = configurator
  }

  process () {
    this.updateDimensionTablePrices()
    this.updateDimensionPrices()
    this.updateColorPrices()
    this.updatePrices()

    return this
  }

  updatePrices () {
    this.configurator.steps.forEach((step) => {
      if (step.options) {
        step.options.forEach((option) => {
          this.updateOptionPrice(option)
        })
      }

      this.updateStepPrice(step)
    })

    const total = this.configurator.steps.reduce(function (price, step) {
      return price + (step.price ? step.price.total || 0 : 0)
    }, 0)

    this.configurator.price.total = total * (this.configurator.quantity || 1)
    // this.configurator.price.total = total;
  }

  updateColorPrices () {
    const self = this

    this.configurator.steps.filter((step) => step.type === 'color').forEach((step) => {
      if (!step.visible) {
        step.price.total = 0

        return
      }

      const selectedColor = step.colors.find(color => color.selected)
      const totalRelatedPrice = self.configurator.steps.filter((filterStep) => step.price_on_steps.indexOf(filterStep.id) !== -1)
        .reduce((totalPrice, step) => totalPrice + step.price.total, 0)

      step.price.total = (totalRelatedPrice / 100) * (selectedColor.special ? step.price_special : step.price)
    })
  }

  updateDimensionPrices () {
    const self = this

    this.configurator.steps.filter((step) => step.type === 'dimension').forEach((step) => {
      // used in calculation functions
      const getAttr = (name, attr) => {
        return self.configurator.steps.find(step => step.name === name)
          .options.find(option => option.selected)
          // [attr]
      }

      if (step && step.price.calc) {
        step.price.total = !step.visible ? 0 : step.price.calc({
          width: step.width.value,
          height: step.height.value,
          app: {
            value: getAttr
          }
        })
      }
    })
  }

  updateDimensionTablePrices () {
    this.configurator.steps.filter((step) => step.type === 'dimension_table').forEach((step) => {
      const option = step.options.find(option => option.selected === true)
      step.price.self = option.price.self
      step.price.total = step.visible ? step.price.self : 0
    })
  }

  updateStepPrice (step) {
    if (step.price && step.options) {
      if (['simpleselect', 'conditional'].indexOf(step.type) !== -1) {
        step.price.options = step.options.reduce(function (price, option) {
          return price + option.price.total
        }, 0)
        // console.log(step.price.options);
        step.price.total = step.visible ? (step.price.self + step.price.options) : 0
      }
    }
  }

  updateOptionPrice (option) {
    if (option.price) {
      const quantityFactor = option.quantity_multiply ? this.configurator.quantity : 1
      if (option.type === 'count') {
        option.price.total = !option.selected || option.disabled ? 0 : option.price.self * quantityFactor * option.value
      } else if (option.type === 'select') {
        option.price.total = !option.selected || option.disabled ? 0 : option.price.self
      } else {
        option.price.total = !option.selected || option.disabled ? 0 : option.price.self * quantityFactor
      }
    }
  }
}
