export default class {
  constructor (configurator) {
    this.configurator = configurator
    this.menge = 0
  }

  process () {
    this.updateDimensionTablePrices()
    this.updateDimensionGaragePrices()
    if (this.configurator.steps.find(s => s.type === 'dimension')) {
      this.updateDimensionPrices()
    }
    this.updateColorPrices()
    this.updatePrices()
    this.updateStandartDimPrice()
    return this
  }

  updatePrices () {
    this.configurator.steps.forEach((step) => {
      if (step.options) {
        step.options.forEach((option) => {
          this.updateOptionPrice(option)
        })
      }
      this.updateStepPrice(step)
    })

    const total = this.configurator.steps.reduce(function (price, step) {
      return price + (step.price ? step.price.total || 0 : 0)
    }, 0)

    // update zaunfelder Pfosten if using Menge
    this.updateMenge()
    this.configurator.price.total = (total + this.menge) * (this.configurator.quantity || 1)
  }

  updateColorPrices () {
    const self = this

    this.configurator.steps.filter((step) => step.type === 'color').forEach((step) => {
      if (!step.visible) {
        step.price.total = 0

        return
      }

      const selectedColor = step.colors.find(color => color.selected)
      const totalRelatedPrice = self.configurator.steps.filter((filterStep) => step.price_on_steps.indexOf(filterStep.id) !== -1)
        .reduce((totalPrice, step) => totalPrice + step.price.total, 0)

      step.price.total = (totalRelatedPrice / 100) * (selectedColor.special ? Number.parseInt(step.price_special) : step.price)
    })
  }

  updateDimensionPrices () {
    const step = this.configurator.steps.find(s => s.model_step)
    const option = step.options.find(o => o.selected)
    const markup = (step && typeof step.markup !== 'undefined') ? step.markup / 100 : 0

    if (option && typeof option.standart_dim !== 'undefined') {
      const standart = option.standart_dim.find(o => o.selected)
      const special = option.special_dim.find(s => s.selected)
      const tax = 19 / 100
      if (special && typeof special !== 'undefined') {
        const markupPercent = (option && option.markup) ? special.price * (Number.parseInt(option.markup) / 100) : special.price * markup
        const priceMarkup = special.price + markupPercent
        const priceTax = priceMarkup * tax
        const total = priceMarkup + priceTax
        // let price_discount = total * discounted;
        option.price.self = total
        option.price.total = step.visible ? option.price.self : 0
      } else {
        if (standart && typeof standart !== 'undefined') {
          const markupPercent = (option && option.markup) ? standart.price * (Number.parseInt(option.markup) / 100) : standart.price * markup
          // calculate
          const priceMarkup = standart.price + markupPercent
          const priceTax = priceMarkup * tax
          const total = priceMarkup + priceTax
          // let price_discount = total * discounted;
          option.price.self = total
          option.price.total = step.visible ? option.price.self : 0
        }
      }
    }
  }

  countHeightTotal (option) {
    if (option.dimension.height && option.dimension.height.price_per) {
      const heightPrice = Number.parseFloat(option.dimension.height.price_per)
      const heightPer = Number.parseInt(option.dimension.height.dim_per)
      const heightValue = Number.parseInt(option.dimension.height.value)
      let heightTotal = 0
      if (heightValue > 100) {
        const parts = heightValue - 100
        const cm = parts / heightPer
        const val = (cm + 1) * heightPrice
        heightTotal = val
      } else {
        heightTotal = heightPrice
      }
      return heightTotal
    }
    return 0
  }

  countHeightAdditionalTotal (option) {
    let heightTotal = 0
    if (typeof option.dimension.height_additional !== 'undefined') {
      const heightPrice = Number.parseFloat(option.dimension.height_additional.price_per)
      const heightPer = Number.parseInt(option.dimension.height_additional.dim_per)
      const heightValue = Number.parseInt(option.dimension.height_additional.value)

      if (heightValue > 100) {
        const parts = heightValue - 100
        const cm = parts / heightPer
        const val = (cm + 1) * heightPrice
        heightTotal = val
      } else {
        heightTotal = heightPrice
      }
    }
    return heightTotal
  }

  updateStandartDimPrice () {
    const step = this.configurator.steps.find(s => s.model_step)
    if (step) {
      const option = step.options.find(o => o.selected)
      const markup = (step && typeof step.markup !== 'undefined') ? step.markup / 100 : 0
      if (option && typeof option.standart_dim !== 'undefined') {
        const dims = option.standart_dim
        const tax = 19 / 100
        const heightTotal = this.countHeightTotal(option) // get height price
        const heightAdditionalTotal = this.countHeightAdditionalTotal(option) // get height additional price
        const discounted = (option && typeof option.discount) ? option.discount / 100 : 0
        // calc prices
        dims.forEach(dim => {
          if (dim && dim.price) {
            const markupPercent = (option && option.markup) ? dim.price * (Number.parseInt(option.markup) / 100) : dim.price * markup
            // calculate
            const priceMarkup = dim.price + markupPercent
            const priceTax = priceMarkup * tax
            const total = priceMarkup + priceTax + heightTotal + heightAdditionalTotal
            const priceDiscount = total * discounted
            dim.discounted = total - priceDiscount
            dim.total = total
          }
        })
      }
    }
  }

  updateDimensionTablePrices () {
    // const modelStep = this.configurator.steps.find(s => s.model_step)
    // const option_selected = model_step.options.find(o => o.selected);
    // const discount = option_selected.discount / 100;
    this.configurator.steps.filter((step) => step.type === 'dimension_table').forEach((step) => {
      const option = step.options.find(option => option.selected)
      /*
      const discounted = option.price.total * discount;
      option.price.discounted = option.price.self - discounted;
      */
      step.price.self = option.price.self
      step.price.total = step.visible ? step.price.self : 0
    })
  }

  updateDimensionGaragePrices () {
    this.configurator.steps.filter((step) => step.type === 'dimension_garage').forEach((step) => {
      const option = step.options.find(option => option.selected)
      const special = step.specials.filter(s => s.selected)
      const percent = 19 / 100
      if (special.length !== 0) {
        const price = special[0].price * percent
        step.price.self = special[0].price + price
        step.price.total = step.visible ? step.price.self : 0
      } else {
        const price = option.price.self * percent
        step.price.self = option.price.self + price
        step.price.total = step.visible ? step.price.self : 0
      }
    })
  }

  updateStepPrice (step) {
    if (step.price && step.options) {
      if (['simpleselect', 'simpletechnic', 'conditional', 'montage_step', 'glass'].indexOf(step.type) !== -1) {
        step.price.options = step.options.reduce((price, option) => {
          return price + option.price.total
        }, 0)
        // checking special simpleselect option
        if (typeof step.price_special !== 'undefined') {
          const selectOption = step.options.find(s => s.selected)
          if (typeof selectOption !== 'undefined' && selectOption.special) {
            const stepPrice = this.configurator.price.total
            step.price.total = (stepPrice / 100) * Number.parseInt(step.price_special)
          } else {
            step.price.total = 0
          }
        } else {
          step.price.total = step.visible ? (step.price.self + step.price.options) : 0
        }
      }
      /*
      if (step.type === 'glass') {
        const dim = this.configurator.steps.find(s => s.type === 'dimension_glass');
        step.price.options = step.options.reduce((price, option) => {
          const type_view = option.type_view;
          if (type_view === 'L' || type_view ==='R') {
            let price_option =  0;
            const h = Number.parseInt(dim.height.value) / 100;
            const l = Number.parseInt(dim.length.value) / 100;
            const m2 = l * h;
            price_option = m2 * option.price.self
            // calc option total
            //option.price.total = price + (m2 * option.price.self)
            return price /*+ option.price.total */ /* + price_option;
          }
          if (type_view == 'F' || type_view == 'B') {
            let price_option =  0;
            const w = Number.parseInt(dim.width.value) / 100;
            const h = Number.parseInt(dim.height.value) / 100;
            const m2 = w * h;
            price_option = m2 * option.price.self;
            // calc option total
            //option.price.total = price + (m2 * option.price.self)
            return price /*+ option.price.total */ /* + price_option;
          }
          // Keil
          if (type_view === 'K') {
            let price_option =  0;
            const l = Number.parseInt(dim.length.value) / 100;
            price_option = l * option.price.self;
            // calc option total
            //option.price.total = price + (l * option.price.self)
            return price + price_option;
          }
        }, 0);
        console.log(step.price)
        step.price.total = step.visible ? (step.price.self + step.price.options) : 0;
      }
      */
    }
  }

  updateOptionPrice (option) {
    if (option.price) {
      const quantityFactor = option.quantity_multiply ? this.configurator.quantity : 1
      if (option.type === 'count') {
        option.price.total = !option.selected || option.disabled ? 0 : option.price.self * quantityFactor * option.value
      // sub options calc
      } else if (option.group === 'zaunfelder') {
        option.price.total = 0
      } else if (option.group === 'pfosten') {
        if (!option.selected || option.disabled) {
          option.price.total = 0
        } else {
          const fance = 2
          option.price.total = option.price.self * fance
          option.quantity = this.configurator.quantity * 2
        }
      // sub options calc
      } else if (option && option.sub_options) {
        let subPrice = 0
        if (option && option.sub_options) {
          option.sub_options.filter(option => {
            if (option.selected) {
              subPrice += option.price
            }
          })
        }
        option.price.self = !option.selected || option.disabled ? 0 : subPrice * quantityFactor
        option.price.total = !option.selected || option.disabled ? 0 : option.price.self * quantityFactor
      } else {
        option.price.total = !option.selected || option.disabled ? 0 : option.price.self * quantityFactor
      }
    }
  }

  updateMenge () {
    const sps = this.configurator.steps.find(step => step.name === 'Pfosten')
    if (sps) {
      const o = sps.options.find(o => o.group === 'zaunfelder')
      if (o && o.selected) {
        const discount = Number.parseFloat(o.discount) / 100 * o.price.self
        const priceDiscounted = o.price.self - discount
        const discounted = (o.discount > 0 ? priceDiscounted : o.price.self)
        const quantity = (this.configurator.quantity > 1 ? this.configurator.quantity + 1 : this.configurator.quantity)
        const total = quantity * discounted
        if (quantity > 1) {
          this.menge = total
        } else {
          this.menge = total
        }
      } else {
        this.menge = 0
      }
    }
  }

  getQuantityStepValue () {
    const value = this.configurator.steps.find(s => s.type === 'quantity').value
    return Number.parseInt(value)
  }

  getModelStepWidthValue () {
    const modelStep = this.configurator.steps.find(s => s.model_step)
    const option = modelStep.options.find(o => o.selected)
    return Number.parseInt(option.dimension.width.value)
  }
}
