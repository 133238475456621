<template>
<!--nav-->
<nav class="navbar navbar-dark bg-black navbar-expand-lg py-0">
  <div class="container">
    <a class="navbar-brand" href="https://konfigurator.loewe-zaun.de">
      <img class="my-1" src="https://image-service.loewe-zaun.de/production/55bf8eed15979.png" width="150">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav mr-auto">
        <li v-for="item in navigation" class="nav-item" :class="[item.children ? 'dropdown' : '']" :key="item.id">
          <a v-if="!item.children" :href="item.url" class="nav-link" :target="item.target">{{ item.name }}</a>
          <a v-else href="#" class="nav-link dropdown-toggle" :id="'navbarDropdown' + item.id" role="button" data-bs-toggle="dropdown" aria-expanded="false" :target="item.target">{{ item.name }}</a>
          <div class="dropdown-menu" :aria-labelledby="'navbarDropdown'+item.id" v-if="item.children">
            <a class="dropdown-item" :href="child.url" :target="child.target" v-for="child in item.children" :key="child.id">{{ child.name }}</a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav mx-auto">
        <li class="nav-item">
          <router-link class="cart-link nav-link pointer bg-danger text-white px-3" :to="{ name: 'cart' }">
            <i class="fas fa-shopping-cart"></i> {{ itemCount }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import { onMounted } from '@vue/runtime-core'
export default {
  name: 'HeaderPartial',
  setup () {
    const store = useStore()
    onMounted(() => {
      store.dispatch('menu/setMenu')
    })
    // computed
    const itemCount = computed(() => {
      const items = store.getters['checkout/items']
      return items.length
    })
    // methods
    const navigation = computed(() => {
      return store.getters['menu/menu']
    })
    return {
      itemCount,
      navigation
    }
  }
}
</script>
