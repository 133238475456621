export const SET_SYSTEM = 'SET_SYSTEM'
export const SET_NAME = 'SET_NAME'
export const SET_DIMENSION = 'SET_DIMENSION'
export const SET_AVAILABLE_WIDTH = 'SET_AVAILABLE_WIDTH'
export const SET_PREVIEW_IMAGES = 'SET_PREVIEW_IMAGES'
export const SET_PREVIEW_IMAGES_LOADING = 'SET_PREVIEW_IMAGES_LOADING'
export const SET_EXAMPLE_IMAGES = 'SET_EXAMPLE_IMAGES'
export const SET_EXAMPLE_IMAGES_LOADING = 'SET_EXAMPLE_IMAGES_LOADING'
export const SET_ANIMATIONS = 'SET_ANIMATIONS'
export const SET_SYSTEM_LOADING = 'SET_CONSTRUCTOR_LOADING'
export const SET_STEPS = 'SET_STEPS'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const SET_PRICE = 'SET_PRICE'
export const SET_LOADING = 'SET_LOADING'
export const SET_LOADING_PRICE_ALL = 'SET_LOADING_PRICE_ALL'
export const SET_PRICE_ALL = 'SET_PRICE_ALL'
export const SET_MENU = 'SET_MENU'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_NEXT_STEP = 'SET_NEXT_STEP'
export const SET_MAILBOX_NAME = 'SET_MAILBOX_NAME'
