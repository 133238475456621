function lazyLoad (path) {
  return () => import('~/pages/' + path).then(m => m.default || m)
}
export default [
  { path: '/', name: 'home', component: lazyLoad('configurator/homepage'), meta: { layout: 'default' } },
  { path: '/show/:name/:definition?', component: lazyLoad('configurator/homepage'), meta: { layout: 'default' } },
  { path: '/thanks', name: 'configurator.thanks', component: lazyLoad('configurator/thanksPage'), meta: { layout: 'default' } },
  { path: '/cart', name: 'cart', component: lazyLoad('checkout/cart'), meta: { layout: 'default' } },
  { path: '/checkout/customer', name: 'checkout.customer', component: lazyLoad('checkout/customer'), meta: { layout: 'default' } },
  { path: '/checkout/payment', name: 'checkout.payment', component: lazyLoad('checkout/payment'), meta: { layout: 'default' } },
  { path: '/checkout/total', name: 'checkout.total', component: lazyLoad('checkout/total'), meta: { layout: 'default' } },
  { path: '/checkout/success', name: 'checkout.success', component: lazyLoad('checkout/success'), meta: { layout: 'default' } },
  // products
  { path: '/cat/:name', name: 'category', component: lazyLoad('products/index'), meta: { layout: 'default' } },
  { path: '/product/:product', name: 'product', component: lazyLoad('products/productDetail'), meta: { layout: 'default' } },
  // constructor
  { path: '/system', name: 'system', component: lazyLoad('system/index'), meta: { layout: 'default' } },
  // errors
  { path: '/notFound', name: 'notFound', component: lazyLoad('errors/notFound'), meta: { layout: 'default' } },
  { path: '/:catchAll(.*)', redirect: 'notFound' }
]
